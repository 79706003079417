<template>
  <div class="page layout-horizontal">
    <div class="layout-horizontal layout-vertical">
      <div class="padding padding-large font-align-right layout-inflexible">
        <ys-button @click="clickAddPrescription">+ 发送处方</ys-button>
      </div>
      <div class="layout-flexible" style="overflow: hidden;">
        <ys-infinite-scroll-view
            style="height: 100%; width: 400px;"
            @loadmore="loadMore"
        >
          <div
              v-for="(prescription, index) in prescriptionList"
              :key="prescription.id"
              class="margin-horizontal"
          >
            <div
                v-if="index === 0 || prescription.procedureState !== prescriptionList[index - 1].procedureState"
                class="font-size-small font-color-placeholder padding padding-small padding-horizontal-large"
            >
              <span
                  v-if="prescription.procedureState != null">{{
                  ProcedureUtils.getStateName(prescription.procedureState)
                }}</span>
              <span>更早</span>
            </div>
            <div
                class="list-item layout-horizontal layout-middle font-size-medium padding-horizontal-large padding-vertical"
                :class="{
                  'is-active': currentPrescription && prescription.id === currentPrescription.id
                }"
                @click="clickPrescription(prescription)"
            >
              <span class="fas fa-prescription-bottle"></span>
              <div class="layout-flexible margin-left">
                <div
                    class="prescription-title"
                    :class="{
                    'is-stopped':  prescription.state === BussinessState.STOPPED,
                  }"
                >
                  <span>{{ prescription.title }}</span>
                  <span v-if="prescription.state === BussinessState.STOPPED" class="font-color-danger">[已停止]</span>
                </div>
                <div class="font-color-secondary font-size-small">{{TimeUtils.format('开始时间：yyyy/MM/dd HH:mm', prescription.startTime)}}</div>
              </div>
              <div class="layout-inflexible">
                <ys-dropdown>
                  <ys-button type="text"><span class="fas fa-ellipsis-h font-color-placeholder"></span></ys-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        class="font-size-medium padding-vertical-large"
                        @click.native="clickEditPrescription(prescription)"
                    ><span class="fas fa-pen-alt"></span>
                      修改
                    </el-dropdown-item>
                    <el-dropdown-item
                        class="font-size-medium padding-vertical-large"
                        @click.native="clickStopPrescription(prescription)"
                    ><span class="fas fa-stop"></span>
                      停止
                    </el-dropdown-item>
                    <el-dropdown-item
                        class="font-size-medium padding-vertical-large font-color-danger"
                        @click.native="clickDeletePrescription(prescription)"
                    ><span
                        class="far fa-trash-alt"></span>
                      删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </ys-dropdown>
              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <prescription-preview-panel
        v-if="currentPrescription"
        :organization-id="organizationId"
        :user-id="userId"
        :prescription-id="currentPrescription.id"
        class="layout-flexible"
        style="max-width: 380px;"
    ></prescription-preview-panel>
    <div class="padding padding-large font-size-extra-large font-color-light-placeholder" v-else>未选择饮食处方</div>
    <prescription-template-picker-dialog
        :organization-id="organizationId"
        title="选择处方模板"
        :visible.sync="prescriptionTemplatePickerDialogVisible"
        @pick="handlePrescriptionTemplatePicked"
    ></prescription-template-picker-dialog>
    <prescription-input-dialog
        v-if="pickedPrescriptionTemplate || editingPrescription"
        :visible.sync="prescriptionInputDialogVisible"
        :organization-id="organizationId"
        :user-id="userId"
        :template-id="pickedPrescriptionTemplate ? pickedPrescriptionTemplate.id : null"
        :prescription-id="editingPrescription ? editingPrescription.id : null"
        @confirm="handlePrescriptionInputConfirm"
    ></prescription-input-dialog>
    <delete-dialog
        v-if="deletingPrescription"
        :visible.sync="deleteDialogVisible"
        message="您确定要删除该饮食处方吗？"
        @confirm="confirmDeletePrescription"
    ></delete-dialog>
    <delete-dialog
        v-if="stoppingPrescription"
        :visible.sync="stoppingDialogVisible"
        message="您确定要停止该饮食处方吗？"
        confirm-button-text="停止"
        @confirm="confirmStopPrescription"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import {ProcedureUtils} from "@/assets/javascript/procedure-utils";
import PrescriptionPreviewPanel from "@/pages/prescription/panel/PrescriptionPanel";
import YsButton from "@/components/wedigets/YsButton";
import YsDropdown from "@/components/wedigets/YsDropdown";
import BusinessState from "@/assets/javascript/business-state";
import PrescriptionInputDialog from "@/pages/prescription/dialog/PrescriptionInputDialog";
import PrescriptionTemplatePickerDialog from "@/components/dialog/PrescriptionTemplatePickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "PrescriptionPanel",
  mixins: [httpapi],
  components: {
    DeleteDialog,
    PrescriptionTemplatePickerDialog,
    PrescriptionInputDialog, YsDropdown, YsButton, YsInfiniteScrollView, PrescriptionPreviewPanel
  },
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 常量
       */
      ProcedureUtils: ProcedureUtils,
      BussinessState: BusinessState,
      TimeUtils: TimeUtils,

      /**
       * 处方列表
       */
      prescriptionList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 当前饮食处方
       */
      currentPrescription: null,

      /**
       * 发送饮食处方
       */
      prescriptionTemplatePickerDialogVisible: false,
      prescriptionInputDialogVisible: false,
      pickedPrescriptionTemplate: null,

      /**
       * 修改饮食处方
       */
      editingPrescription: null,


      /**
       * 删除饮食处方
       */
      deleteDialogVisible: false,
      deletingPrescription: null,

      /**
       * 停止饮食处方
       */
      stoppingDialogVisible: false,
      stoppingPrescription: null,

    }
  },
  computed: {
    inputParams: function () {
      const {organizationId, userId} = this;
      return {
        organizationId,
        userId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.prescriptionList = [];
        this.currentPrescription = null;
        this.pageNum = 1;
        this.totalPages = 0;
        this.loadPrescriptionList();
      },
      immediate: true,
    }
  },
  methods: {
    clickPrescription: function (prescription) {
      this.currentPrescription = prescription;
    },
    clickAddPrescription: function () {
      this.prescriptionTemplatePickerDialogVisible = true;
    },
    handlePrescriptionTemplatePicked(prescriptionTemplateList) {
      this.pickedPrescriptionTemplate = prescriptionTemplateList[0];
      this.prescriptionInputDialogVisible = true;
    },
    handlePrescriptionInputConfirm(prescription) {
      this.$appendBefore(this.prescriptionList, [prescription]);
      if (this.editingPrescription && prescription.id !== this.editingPrescription.id) {
        this.editingPrescription.state = BusinessState.STOPPED;
      }
      this.loadPrescriptionList();
      this.editingPrescription = null;
      this.currentPrescription = prescription;
    },
    clickEditPrescription: function (prescription) {
      this.editingPrescription = prescription;
      this.prescriptionInputDialogVisible = true;
      j
    },
    clickDeletePrescription(prescription) {
      this.deletingPrescription = prescription;
      this.deleteDialogVisible = true;
    },
    confirmDeletePrescription() {
      this.deletePrescription(this.deletingPrescription);
    },
    clickStopPrescription(prescription) {
      this.stoppingPrescription = prescription;
      this.stoppingDialogVisible = true;
    },
    confirmStopPrescription() {
      this.stopPrescription(this.stoppingPrescription);
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadPrescriptionList: function (pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/prescription/list/${this.organizationId}/${this.userId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let prescriptionList = pageNum == 1 ? [] : this.prescriptionList;
            this.$appendAfter(prescriptionList, res.data.list);
            this.prescriptionList = prescriptionList;
            this.pageNum = res.data.pageNum;
            this.totalPages = res.data.pages;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadPrescriptionList(this.pageNum + 1);
      }
    },
    deletePrescription: function (prescription) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/prescription/${prescription.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            let deleteIndex = -1;
            for (let n = this.prescriptionList.length - 1; n >= 0; n--) {
              if (this.prescriptionList[n].id === prescription.id) {
                this.prescriptionList.splice(n, 1);
                deleteIndex = n;
                break;
              }
            }
            if (this.currentPrescription?.id === prescription.id) {
              if (this.prescriptionList.length > 0) {
                if (deleteIndex <= 0) {
                  this.currentPrescription = this.prescriptionList[deleteIndex];
                } else {
                  this.currentPrescription = this.prescriptionList[deleteIndex - 1];
                }
              } else {
                this.currentPrescription = null;
              }
            }
          })
          .catch(() => {
            this.$message.error('操作失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    stopPrescription: function (prescription) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPatch({
        path: `/prescription/stop/${prescription.id}`
      })
          .returnWhenCodeMatches()
          .then((res) => {
            this.$appendAfter(this.prescriptionList, [res.data]);
          })
          .catch(() => {
            this.$message.error('操作失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    }
  }
}
</script>

<style scoped>

.list-item {
  width: unset;
  min-width: unset;
}

.list-item.is-active {
  border: 1px solid #ff5500;
  background-color: #faf4f5;
}

.prescription-title.is-stopped {
  text-decoration: line-through;
  opacity: .5;
}

</style>